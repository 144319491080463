<template>
  <div>
    <SearchProjects/>
    <ListCardProjects :projects="projects"/>
  </div>
</template>
<script>
import SearchProjects from '@/views/pages/StrategyProjects/Components/SearchProjects.vue';
import ListCardProjects from '@/views/pages/StrategyProjects/Components/ListCardProjects.vue';

import exception from '@/mixins/exception';
import content from '@/mixins/content';

import ProjectService from '@/services/ProjectService';

export default {
  name: 'PrivateProjectsPage',
  components: {
    SearchProjects,
    ListCardProjects,
  },
  mixins: [exception, content],
  data() {
    return {
      page: 1,
      perPage: 5,
      totalPages: 1,
      projects: [],
      searchData: {},
    };
  },
  created() {
    if (Object.keys(this.$route.query).length) {
      this.$router.replace({
        query: {},
      });
    }

    window.addEventListener('scroll', async () => {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = document.documentElement;
      const footer = document.getElementsByClassName('footer');
      if (window.innerWidth <= 768
        && scrollTop + clientHeight >= scrollHeight - footer[0].scrollHeight) {
        await this.getProjects();
      }
      if (window.innerWidth > 768 && scrollTop + clientHeight >= scrollHeight) {
        await this.getProjects();
      }
    });

    this.$eventBus.$on('search', this.searchProjects);
    this.$eventBus.$on('addLike', this.onAddLike);
    this.$eventBus.$on('removeLike', this.onRemoveLike);

    // this.getProjects();
  },
  methods: {
    async getProjects() {
      let res = null;
      if (this.page <= this.totalPages) {
        const query = {
          page: this.page,
          perPage: this.perPage,
        };
        try {
          res = await ProjectService.projects({ ...query, ...this.searchData });
          this.projects.push(...res.data);
          this.totalPages = res.headers['x-pagination-page-count'];
          this.page = query.page + 1;
        } catch (e) {
          // this.projects = [];
          this.handleException(e);
        }
      }
    },
    searchProjects(data) {
      if (Object.keys(this.$route.query).length) {
        this.$router.replace({
          query: {},
        });
      }

      if (data.type > 0) {
        this.searchData.type = data.type;
      } else {
        delete this.searchData.type;
      }

      if (data.text) {
        this.searchData.text = data.text;
      } else {
        delete this.searchData.text;
      }

      if (data.category_id.length) {
        this.searchData.category_id = data.category_id;
      } else {
        delete this.searchData.category_id;
      }

      // this.searchData.page = '1';
      // if (!this.$router.currentRoute.params.searchParams) {
      //   window.history.pushState(
      //     {},
      //     null,
      //     this.$route.path,
      //   );
      // }

      this.projects = [];
      this.page = 1;
      this.totalPages = 1;
      // console.log(this.searchData.text);
      this.getProjects();
    },
    async onAddLike(projectID) {
      try {
        await ProjectService.addLike(projectID);
        const projectUpdate = this.projects.find((project) => project.id === projectID);

        projectUpdate.like = true;
        projectUpdate.cnt_like += 1;
      } catch (e) {
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
        this.handleException(e);
      }
    },
    async onRemoveLike(projectID) {
      try {
        await ProjectService.removeLike(projectID);
        const projectUpdate = this.projects.find((project) => project.id === projectID);

        projectUpdate.like = false;
        projectUpdate.cnt_like -= 1;
      } catch (e) {
        this.showAndHideAlertSuccess(e.response.data.message, 'danger');
        this.handleException(e);
      }
    },
  },
  beforeDestroy() {
    this.$eventBus.$off('search', this.searchProjects);
    this.$eventBus.$off('addLike', this.onAddLike);
    this.$eventBus.$off('removeLike', this.onRemoveLike);
    window.removeEventListener('scroll', async () => {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = document.documentElement;
      const footer = document.getElementsByClassName('footer');
      if (window.innerWidth <= 768
        && scrollTop + clientHeight >= scrollHeight - footer[0].scrollHeight) {
        await this.getProjects();
      }
      if (window.innerWidth > 768 && scrollTop + clientHeight >= scrollHeight) {
        await this.getProjects();
      }
    });
  },
};
</script>
<style lang="scss" scoped>

</style>
