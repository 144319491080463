<template>
  <div class="projects__item">
    <div class="projects__content">
      <div class="top-projects__top">
        <span class="action-like-num"
              style="padding-right:15px;color:white;">{{ project.cnt_like }}</span>
        <a href="#" class="top-projects__like" @click.prevent="onHandleLike">
          <span v-if="project.like" class="icon-like--active"></span>
          <span v-else class="icon-like--notactive"></span>
        </a>
      </div>
      <div class="top-projects__title" :style="{backgroundColor: project.style_color}">
        <h3>{{ project.name }}</h3>
      </div>
      <div class="top-projects__body">
        <div class="top-avatar__wrapper">
          <div class="top-avatar__img">
            <img v-if="project.author.avatar" :src="project.author.avatar" alt="Avatar">
            <img v-else src="@/assets/images/avatar.svg" alt="Avatar">
            <!--            <img src="images/content/avatar.jpg" alt="">-->
          </div>
          <div class="top-avatar__info">
            <div class="top-avatar__author">{{ project.author.name }}</div>
            <div class="top-avatar__time">{{ project.created_at }}</div>
          </div>
        </div>
        <ul class="category__list">
          <li class="category__item" v-if="project.style_name">
            <a class="category__color--1" style="cursor: default"
               tabindex="0">{{ project.style_name }}
            </a>
          </li>
          <li class="category__item">
            <a class="category__color--2" style="cursor: default"
               tabindex="0">{{ project.category_id }}
            </a>
          </li>
          <li class="category__item">
            <a class="category__color--3" style="cursor: default" tabindex="0">{{ project.type }}
            </a>
          </li>
        </ul>
        <hr>
        <div class="top-projects__text">
          {{ project.text_short }}
        </div>
      </div>
    </div>
    <div class="top__bottom">
      <router-link
        class="btn btn-primary"
        :to="{ name: 'strategyProjectPageView', params: { id: project.slug } }">
        View now
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardProject',
  components: {},
  mixins: [],
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {
  },
  computed: {
    typeText() {
      return this.project.status === 'request' ? 'the project is awaiting confirmation' : this.project.type;
    },
  },
  methods: {
    onHandleLike() {
      if (this.project.like) {
        this.$eventBus.$emit('removeLike', this.project.id);
      } else {
        this.$eventBus.$emit('addLike', this.project.id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
