<template>
  <div class="search-content">
    <div class="container">
      <div class="search-content__inner">
        <div v-if="text && alert" class="container_alert">
          <Alert :variant="variant" v-if="text">{{ text }}</Alert>
        </div>
        <div v-if="this.$store.getters.getAuthUser" class="user-cabinet__area-top">
          <router-link class="btn btn-primary" :to="{ name: 'strategyProjectCreate' }">
            New Strategy Project
          </router-link>
        </div>
          <div class="projects__list">
            <CardProject v-for="project in projects" :key="project.id" :project="project"/>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardProject from '@/views/pages/StrategyProjects/Components/CardProjectV2.vue';
import Alert from '@/components/Alert.vue';

import alert from '@/mixins/alert';

export default {
  name: 'ListCardProjects',
  components: {
    CardProject,
    Alert,
  },
  mixins: [alert],
  props: {
    projects: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {
  },
  computed: {},
  methods: {},
  mounted() {
    if (this.$route.params.alertText) {
      this.alert = this.$route.params.alertText;
    }
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 768px) and (max-width: 992px) {
  .projects__list {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
